import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { LiteMember } from "@/model/general";
import { ApplauseLog, SiteInitialState, ToolboxTab } from "@/model/site";

const initialState: SiteInitialState = {
  applauseLogs: [],
  devMode: false,
  liteUserType: "CustomerWithEmail",
};

export const SiteSlice = createSlice({
  initialState,
  name: "siteSlice",
  reducers: {
    AddApplauseLog: (state, action: PayloadAction<ApplauseLog>) => {
      state.applauseLogs = [...state.applauseLogs, action.payload];
    },
    ClearApplauseLog: (state) => {
      state.applauseLogs = [];
    },
    SetDevMode: (state) => {
      console.log("Developer mode activated");
      state.devMode = true;
      state.liteUserType = "Staff";
    },
    SetDevToolboxTab: (
      state,
      action: PayloadAction<ToolboxTab | undefined>
    ) => {
      state.devToolboxTab = action.payload;
    },
    SetLiteMember: (state, action: PayloadAction<LiteMember>) => {
      state.liteUser = action.payload;
    },
  },
});

export const {
  AddApplauseLog,
  ClearApplauseLog,
  SetDevToolboxTab,
  SetDevMode,
  SetLiteMember,
} = SiteSlice.actions;
export default SiteSlice.reducer;
